import './App.css';
import React from 'react';
import { GiQueenCrown } from 'react-icons/gi';
import { FaLocationArrow, FaStar, FaSpinner, FaGooglePlay, FaAppStoreIos } from 'react-icons/fa';
import { Container, Row, Col, Navbar, Nav, Card, Button, Spinner } from 'react-bootstrap';
import ScrollBox from './scrollbox';
import axios from 'axios';
import WeaponModal from './components/shared/weaponModal/weaponModal';

export default class App extends React.Component {
    state = {
        navExpanded: false,
        weapons: [],
        showWeaponModal: false,
        selectedWeapon: null,
        selectedAttachment: null,
        loading: true
    }

    componentDidMount = async () => {
        const weapons = await axios.get('https://api.warzonemeta.io/weapons')
        this.setState({
            weapons: weapons['data']['weapons'].sort((a, b) => (a.rating < b.rating) ? 1 : -1),
            loading: false
        });
    }

    getAttachmentDetails = async (attachmentId) => {
        const attachmnt = await axios.post('https://api.warzonemeta.io/attachments', { attachment_id: attachmentId });
        this.setState({ selectedAttachment: attachmnt.data.attachment });
    }

    openWeaponDetails = (e, weapon) => {
        e.preventDefault();
        this.getAttachmentDetails(weapon.attachmentId);
        this.setState({ selectedWeapon: weapon, showWeaponModal: true })
    }

    setNavExpanded = (expanded) => {
        this.setState({ navExpanded: expanded });
    };

    closeNav = () => {
        this.setState({ navExpanded: false });
    };

    render() {
        return !this.state.loading ? (
            <div id={"home"} style={{ padding: '20px' }}>
                <WeaponModal show={this.state.showWeaponModal}
                    handleClose={() => this.setState({ showWeaponModal: false })}
                    selectedWeapon={this.state.selectedWeapon}
                    selectedAttachment={this.state.selectedAttachment} />
                {/* NAVBAR */}
                <div>
                    <Navbar fixed="top" bg="light" expand="lg" onToggle={this.setNavExpanded} expanded={this.state.navExpanded}>
                        <Navbar.Brand href="#home"> <GiQueenCrown /> Warzone Meta</Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">

                            <Nav className="me-auto" onSelect={this.closeNav}>
                                <Nav.Link href="#best-weapons">Best weapons</Nav.Link>
                                <Nav.Link href="#long-range">Long Range</Nav.Link>
                                <Nav.Link href="#short-range">Short Range</Nav.Link>
                                <Nav.Link href="#snipers">Snipers</Nav.Link>
                                <Nav.Link href="#sniper-support">Sniper support</Nav.Link>
                            </Nav>

                            <Navbar.Brand
                                href="https://play.google.com/store/apps/details?id=blog.nbtl.warzone_meta">
                                <FaGooglePlay /></Navbar.Brand>

                                <Navbar.Brand
                                href="https://apps.apple.com/us/app/warzone-meta/id1611065669">
                                <FaAppStoreIos /></Navbar.Brand>                                
                        </Navbar.Collapse>
                    </Navbar>
                </div>

                {/* CONTENT */}
                <div id={"best-weapons"} style={{ marginTop: '60px' }}>
                    <h3>Best current weapons</h3>
                    <ScrollBox>
                        {this.state.weapons.filter(w => w.rating === '5').map(weapon => {
                            return <Card onClick={e => this.openWeaponDetails(e, weapon)}
                                style={{ width: '18rem', height: '22rem' }}>
                                <Card.Img variant="top" src={weapon.image} />
                                <Card.Body>
                                    <Card.Title>{weapon.name}</Card.Title>
                                    <Card.Text>
                                        <div>
                                            {Array.apply(0, Array(parseInt(weapon.rating))).map(function (x, i) {
                                                return <FaStar />;
                                            })}
                                        </div>
                                        {weapon.description.length > 80 ? weapon.description.slice(0, 80) + " ...." : weapon.description}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        })}
                    </ScrollBox>
                </div>

                {/* WEAPON ROW */}
                <div id={"long-range"} style={{ marginTop: '40px' }}>
                    <h3>Long Range (60 Meter+)</h3>
                    <ScrollBox>
                        {this.state.weapons.filter(w => w.range_type === 'Long-Range').map(weapon => {
                            return <Card onClick={e => this.openWeaponDetails(e, weapon)}
                                style={{ width: '18rem', height: '22rem' }}>
                                <Card.Img variant="top" src={weapon.image} />
                                <Card.Body>
                                    <Card.Title>{weapon.name}</Card.Title>
                                    <Card.Text>
                                        <div>
                                            {Array.apply(0, Array(parseInt(weapon.rating))).map(function (x, i) {
                                                return <FaStar />;
                                            })}
                                        </div>
                                        {weapon.description.length > 80 ? weapon.description.slice(0, 80) + " ...." : weapon.description}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        })}
                    </ScrollBox>
                </div>

                <div id={"short-range"} style={{ marginTop: '40px' }}>
                    <h3>Short Range (0 - 20 Meter)</h3>
                    <ScrollBox>
                        {this.state.weapons.filter(w => w.range_type === 'Short-Range').map(weapon => {
                            return <Card onClick={e => this.openWeaponDetails(e, weapon)}
                                style={{ width: '18rem', height: '22rem' }}>

                                <Card.Img variant="top" src={weapon.image} />
                                <Card.Body>
                                    <Card.Title>{weapon.name}</Card.Title>
                                    <Card.Text>
                                        <div>
                                            {Array.apply(0, Array(parseInt(weapon.rating))).map(function (x, i) {
                                                return <FaStar />;
                                            })}
                                        </div>
                                        {weapon.description.length > 80 ? weapon.description.slice(0, 80) + " ...." : weapon.description}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        })}
                    </ScrollBox>
                </div>


                <div id={"snipers"} style={{ marginTop: '40px' }}>
                    <h3>Snipers (80 Meter+)</h3>
                    <ScrollBox>
                        {this.state.weapons.filter(w => w.range_type === 'Sniper').map(weapon => {
                            return <Card onClick={e => this.openWeaponDetails(e, weapon)}
                                style={{ width: '18rem', height: '22rem' }}>
                                <Card.Img variant="top" src={weapon.image} />
                                <Card.Body>
                                    <Card.Title>{weapon.name}</Card.Title>
                                    <Card.Text>
                                        <div>
                                            {Array.apply(0, Array(parseInt(weapon.rating))).map(function (x, i) {
                                                return <FaStar />;
                                            })}
                                        </div>
                                        {weapon.description.length > 80 ? weapon.description.slice(0, 80) + " ...." : weapon.description}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        })}
                    </ScrollBox>
                </div>

                <div id={"sniper-support"} style={{ marginTop: '40px' }}>
                    <h3>Sniper support (0-50 Meter)</h3>
                    <ScrollBox>
                        {this.state.weapons.filter(w => w.range_type === 'Sniper-Support').map(weapon => {
                            return <Card onClick={e => this.openWeaponDetails(e, weapon)}
                                style={{ width: '18rem', height: '22rem' }}>

                                <Card.Img variant="top" src={weapon.image} />
                                <Card.Body>
                                    <Card.Title>{weapon.name}</Card.Title>
                                    <Card.Text>
                                        <div>
                                            {Array.apply(0, Array(parseInt(weapon.rating))).map(function (x, i) {
                                                return <FaStar />;
                                            })}
                                        </div>
                                        {weapon.description.length > 80 ? weapon.description.slice(0, 80) + " ...." : weapon.description}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        })}
                    </ScrollBox>
                </div>
            </div>
        ) : <div style={{ width: '300px', height: '300px', margin: 'auto', marginTop: '150px' }}><Spinner animation="grow"
            size="xl" />
        </div>;
    }
}
