import React, {useEffect} from 'react'

import {Modal, Button, Card, Table, Spinner} from 'react-bootstrap';
import {FaStar} from 'react-icons/fa';

const RESERVED_ATTACHMENT_KEYS = [
    "__typename",
    "_lastChangedAt",
    "createdAt",
    "_version",
    "updatedAt",
    "id",
    "title",
];

export default function weaponModal(props) {
    return props.selectedWeapon ? (
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Body>
                <Card className="bg-dark text-white">
                    <Card.Img src={props.selectedWeapon.image} alt="Card image"/>
                    <Card.ImgOverlay>
                        <Card.Title>{props.selectedWeapon.name}</Card.Title>
                        <Card.Text>Type: {props.selectedWeapon.type} - {props.selectedWeapon.range_type.replaceAll("_", " ")}</Card.Text>
                    </Card.ImgOverlay>
                </Card>
                <br/>
                <div>
                    {Array.apply(0, Array(parseInt(props.selectedWeapon.rating))).map(function (x, i) {
                        return <FaStar/>;
                    })}
                </div>
                <br/>
                <p>{props.selectedWeapon.description}</p>
                <br/><br/>
                {props.selectedAttachment ? <><h4>Attachments</h4>
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Best option</th>
                        </tr>
                        </thead>
                        <tbody>
                        {Object.keys(props.selectedAttachment).map(function (key) {
                            if (!(RESERVED_ATTACHMENT_KEYS.includes(key))) {
                                return <tr>
                                    <td>{key.toLocaleUpperCase()}</td>
                                    <td>{props.selectedAttachment[key]}</td>
                                </tr>
                            }
                        })}
                        </tbody>
                    </Table>
                </> : <><Spinner animation="grow"
                                 size="xl"/><p>Loading attachment details...</p></>}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    ) : <p>Something went wrong...</p>
}

